import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'


const ThankYouPage = () => (

  <div className="Container page_template">
    <div className="thank-you wrap">
      <h1>Thank <strong>You</strong></h1>
      <p>While we do our best to respond quickly, it may take up to 48 hours to receive a response from us during peak seasons.</p>
      <p>
        <a href="/">
          <button className="button_primary" type="button">Return to homepage</button>
        </a>
      </p>
    </div>
  </div>
)

export default withPrismicUnpublishedPreview(ThankYouPage)
